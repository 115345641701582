@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: rgb(211, 216, 232);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background-color: #0d1224;
}

#sticky-card-1 {
  --index: 1;
}

#sticky-card-2 {
  --index: 2;
}

#sticky-card-3 {
  --index: 3;
}

#sticky-card-4 {
  --index: 4;
}

.sticky-card {
  // padding-top: calc(var(--index) * 2.5rem);
  top: calc(var(--index) * 4rem);
}

.profile-image {
  height: fit-content;
}